import {BToast} from "bootstrap-vue";
import {translationHelper} from "./translation-helper";

export const toastHelper = {
    successTitle,
    success,
    error
}

function successTitle(title, message) {
    let bootStrapToaster = new BToast();
    bootStrapToaster.$bvToast.toast(message, {
        title: title,
        variant: "success",
        solid: true
    });
}

function success(message) {
    successTitle(translationHelper.t('message.success'), message)
}

function error(message) {
    let bootStrapToaster = new BToast();
    bootStrapToaster.$bvToast.toast(message, {
        title: translationHelper.t('message.error'),
        variant: "danger",
        solid: true
    });
}