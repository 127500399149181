<template>
  <b-modal
      v-if="form.allegroAccount"
      v-model="visibleModal"
      title="Zmiana typu konta Allegro"
      title-class="font-18"
      hide-footer
      @hide="hideModal"
      @esc="hideModal">
    <h4 class="text-center">Zmiana typu konta Allegro dla: {{ form.allegroAccount.name }}</h4>

    <div class="form-group mb-4">
      <label>Typ</label>
      <vue-multiselect
          v-model="form.allegroAccount.type"
          :custom-label="getLabel"
          :options="accountTypes"
          placeholder="Wybierz typ"
          :class="{ 'is-invalid': $v.form.allegroAccount.type.$error }"
          :show-labels="false"
      />
      <div v-if="!$v.form.allegroAccount.type.required" class="invalid-feedback">{{ $t('message.required') }}</div>
    </div>

    <div class="text-right">
      <b-button @click="changeAllegroAccountType" variant="success">{{ $t('message.save') }}</b-button>
      <b-button class="ml-1" variant="danger" @click="hideModal">{{  $t('message.cancel') }}</b-button>
    </div>
  </b-modal>
</template>

<script>
import {required} from "vuelidate/lib/validators";
import {errorCatcher} from "../../helpers/error-catcher";
import axios from "axios";
import Swal from "sweetalert2";

export default {

  data() {
    return {
      visibleModal: false,

      form: {
        allegroAccount: null
      },

      accountTypes: []
    }
  },

  validations: {
    form: {
      allegroAccount: {
        type: { required }
      }
    }
  },

  methods: {
    getLabel(type) {
      if (type === "EXTERNAL") {
        return "Zewnętrzny"
      }

      return type
    },

    openModal(allegroAccount) {
      this.form.allegroAccount = Object.assign({}, allegroAccount)
      this.visibleModal = true
    },

    hideModal() {
      this.visibleModal = false
      this.form.allegroAccount = null
    },

    async changeAllegroAccountType() {
      this.$v.form.$touch();
      if (this.$v.form.$invalid) {
        return;
      }

      try {
        const json = {
          allegroAccountId: this.form.allegroAccount.id,
          type: this.form.allegroAccount.type
        }

        await axios.post(`/allegro/change-account-type`, json, {
          data: {},
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        });

        this.hideModal()
        await Swal.fire("Sukces!", "Pomyślnie zmieniono typ konta", "success")
            .then(() => this.$emit("success"));
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    async loadAccountTypes() {
      try {
        const {data} = await axios.get(`/allegro/account-types`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {}
        })

        this.accountTypes = data
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    }
  },

  async created() {
    await this.loadAccountTypes()
  }

}
</script>